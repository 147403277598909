html,
body {
    background-color: #fff;
    color: #404040;
    font-size: 16px;
    line-height: 1.4;
    font-family: -apple-system, BlinkMacSystemFont, „Segoe UI“, Roboto, Helvetica, Arial, sans-serif,
    „Apple Color Emoji“, „Segoe UI Emoji“, „Segoe UI Symbol“;
    margin: 0;
    min-height: 100vh;
    scroll-behavior: smooth;
}

a:link,
a:visited,
a:hover,
a:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

a:hover {text-decoration: underline;}

#wrapper {
    max-width: 1736px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

header {
    position: sticky;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    z-index: 999;
}

#breadcrumb {
    position: sticky;
    /* this feels so wrong, but fixes the zucken on scroll */
    top: 126.5px;
    background-color: #fff;
    height: 54px;
    margin-bottom: 40px;
}

#mapContainer {
    width: 100%;
    height: 600px;
    padding: 32px 0;
}

.container {
    /* parent container - margin */
    max-width: 1656px;
    margin: 0 40px;
}



.container .content {
    width: 765px;
    margin: 0 auto;
}

.container div .ezrichtext-field {
    margin: 0 auto;
}


h1, h2, h3, h4, h5 {
    color: #000;
    font-weight: 700;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    overflow-wrap: break-word;
    margin: 8px 0;
}

h3 {
    color: #048500
}

.center {
    text-align: center;
}


.flexRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
}

.flexRow .widget {
    width: 765px;
}

.charts-container {
    display: flex;
    justify-content: space-between;
}

.charts-container div {
    width: 45%;
}

.widget .charts-container {
    flex-direction: column;
    flex-wrap: nowrap;
}
.widget-header {
    min-height: 50px;
}

.widget-content {
    border-top: 1px solid #E0E0E0;
}

.widget-footer  {
    padding: 16px 0;
}

.widget-footer a {
    text-decoration: underline;
    color: #048500;

}

.widget .charts-container div {
    flex: 1 1 auto;
    width: 100%
}

.widget .report {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 32px 0;
    border-bottom: 1px solid #E0E0E0;
}

.widget .report:last-child{
    border-bottom: 1px solid #E0E0E0;
}

.widget .report-image {
    flex: 0 1 auto;
    width: 314px;
}

.report-image img {
    width: 100%;
    height: auto;
}

.widget .report-content {
    flex: 1 1 auto;
    margin: 0 0 0 68px;
}

.widget .report-content p {
    margin: 0;
}

.widget .report-content .state {
    margin: 16px 0;
}

.color-brand,
.active {color: #048500;}

h1 {
    font-size: 3.125rem;
    line-height: 3.75rem;
}

h2 {
    font-size: 2.25rem;
    line-height: 3.125rem;
}

h3 {
    font-size: 1.75rem;
    line-height: 3.125rem;
}

h4 {
    font-size: 24px;
    line-height: 1.5;
}

h5 {
    font-size: 1rem;
}

.btn {
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    min-height: 50px;
    padding: 12px 18px;
    text-align: center;
}
.btn--primary {
    background: #048500;
    border-color: #048500;
    color: #fff;
}
.btn--primary:hover {
    background: #036c00;
    border-color: #036c00;
    color: #fff;
}
.btn--secondary {
    border-color: #000;
    color: #000;
}
.btn--secondary:hover {
    border-color: grey;
    color: grey;
}

.btn--secondary:hover svg {fill: grey;}

a.link {
    align-items: center;
    color: #048500;
    display: inline-flex;
    font-weight: 700;
    line-height: 1rem;
    text-decoration: underline;
}
a:hover {
    text-decoration: none;
}

.logo {
    margin: 20px 40px;
}
.store-logo {
        margin: 10px;
}

.f11a {flex: 1 1 auto;}
.f00a {flex: 0 0 auto;}


.scroll-to-top {
    position: fixed;
    background: #fff;
    border: none;
    border-radius: 10px;
    bottom: 20px;
    padding: 0;
    right: 20px;
    height: 54px;
    width: 54px;
    margin: 3px;
}

.scroll-to-top > img {
    height: 44px;
    width: 44px;
}
.scroll-to-top:hover {
    border: none;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12);
}
footer {
    text-align: center;
    margin-bottom: 60px;
    margin-top: 40px;
}
.footer-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 1rem 1rem 0;
}

.footer-link {
    margin: 0 12px 0 0;
}

.external-link {
    background: #fff;
    border: 2px solid #EFEFEF;
    border-radius: 7px;
    display: inline-flex;
    padding: 1rem 2rem;
    margin: .75rem 0 2rem;
}

.external-link .icon {
    margin: 0 .75rem 0 -0.75rem;
}

.state {
    border-radius: 7px;
    color: #fff;
    display: inline-block;
    padding: 3px 10px;
}
.state-start {
    background-color: #434A54;
}
.state-arrived {
    background-color: #DA4453;
}
.state-inProcess {
    background-color: #F6BB42;
    color: #404040;
}
.state-delayed {
    background-color: #E9573F;
}
.state-done {
    background-color: #8CC152;
    color: #404040;
}
.state-wontFix {
    background-color: #967ADC;
}
.state-archived {
    background-color: #000000;
}

a.infoLink {
    color: #048500;
    text-decoration: underline;
    font-weight: bold;
    display: flex;
}

.infoLink:before {
    background-image: url("/public/assets/images/icons/info.svg");
    background-repeat: no-repeat;
    background-position: left center;
    width: 24px;
    height: 24px;
    display: inline-block;
    content: '';
}

.icon {
    align-items: center;
    display: inline-flex;
    height: 24px;
    margin: 0 .25rem 0 0;
}

nav.mainNav {
    font-size: 1.25rem;
    font-weight: 700;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    line-height: 1.9375rem;
    padding: 0 40px;
    border-bottom: 1px solid #C5C6C7;
    display: flex;
}

nav.mainNav a {
    padding: 0 20px;
    border-left: 1px solid #C5C6C7;
}

nav.mainNav a:hover,
nav.mainNav a.active {
    color: #048500;
}

.breadcrumbs {
    display: flex;
    padding: 20px 40px 10px;
    align-items: center;
}

.breadcrumbs > a:first-child {
    text-indent: -999rem;
    background-image: url("/public/assets/images/icons/KF_signet.svg");
    background-repeat: no-repeat;
    height: 16px;
    width: 19px;
}

.pagination nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    border: none;
    flex-wrap: wrap;
}
.pagination nav > a,
.pagination nav > span {
    display: flex;
    align-items: center;
    border: 1px solid #000;
    border-radius: 7px;
    padding: 2px 12px;
    margin: .25rem 5px;
    height: 31px;
    max-width: 31px;
}
.pagination nav > a:hover,
.pagination nav > span.current {
    background-color: #048500;
    border: 1px solid #048500;
    color: #fff;
    text-decoration: none;
}
.pagination nav > a:hover svg,
.pagination nav > span.current svg {fill: #fff;}
.pagingation nav > span.disabled {
    border-color: #ccc;
    cursor: disabled;
    color: #ccc;
}

.page-links {
    display: flex;
    justify-content: center;
}

.page-link {
    background: #fff;
    border: 2px solid #EFEFEF;
    border-radius: 7px;
    display: inline-flex;
    padding: 16px 32px;
    margin: 10px 20px;
}

.single-page-pagination {
    margin: 30px 0;
}
.single-page-pagination > a {
    align-items: center;
    display: inline-flex;
    font-size: 1rem;
    font-weight: 600;
    min-height: unset;
    padding: 4px 20px 4px 10px;
}
.hero picture {
    display: block;
    height: 650px;
    overflow: hidden;
}

.hero img {
    width: 100%;
    height: 100%;
    max-height: 650px;
    object-fit: cover;
}

.filter-form {
    margin:30px 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.filterWrapper{
    margin: 18px 0;
}

.filter-menu-btn {display: none;}

.filter {
    display: inline-flex;
    position: relative;
}
.filter-btn {
    align-items: center;
    border-radius: 7px;
    border: 1px solid #C5C6C7;
    display: flex;
    justify-content: space-between;
    min-width: 250px;
    padding: 20px;
}
.filter-dropdown {
    max-height: 40vh;
    background-color: #fff;
    border: 1px solid #C5C6C7;
    border-radius: 7px;
    display: none;
    font-size: .85rem;
    left: 0;
    min-width: 250px;
    position: absolute;
    right: 0;
    top: calc(1.5rem + 44px);
    overflow: auto;
    z-index: 1;
}

.filter .filter-btn span {
    display: flex;
    align-items: center;
    transition: all .3s ease-in;
    transform: rotate(0deg)
}
.filter.open .filter-btn span {transform: rotate(180deg)}
.filter.open .filter-dropdown {display: block}

.filter-dropdown > div {
    margin: 0 20px;
    padding: 10px 0;
    border-bottom: 1px solid #C5C6C7;
}
.filter-dropdown > div:last-child {border: 0;}

.filter-item {
    display: flex;
}

input[type="checkbox"] {
    appearance: none;
    aspect-ratio: 1/1;
    -webkit-appearance: none;
    border: 2px solid #717171;
    cursor: pointer;
    border-radius: 3px;
    height: 16px;
    margin: 3px 8px 0 0;
    position: relative;
    width: 16px;
}

input[type="checkbox"]:checked {
    background-color: #048500;
    border-color: #048500;
}

input[type="checkbox"]:checked ~ label {
    color: #048500;
}

input[type="checkbox"]:checked::after {
    content: url("/public/assets/images/icons/done.svg");
    display: block;
    height: 16px;
    left: -2px;
    position: absolute;
    top: -2px;
    width: 16px;
}

.filter-reset {
    background-color: rgba(225,0,26,0.25);
    display: none;
    font-size: 1rem;
    margin: 0!important;
    padding: 15px 20px!important;
    justify-content: space-between;
}

.filter-reset.shown {
    display: flex;
}

#mobile-menu {display: none;}

/*
 * Mobile Styles
 */

@media screen and (max-width: 980px) {
    header, #breadcrumb {
        position: unset;
        z-index: unset;
    }

    #wrapper {
        margin: 0 16px;
        overflow-x: hidden;
    }

    h1 {
        font-size: 1.75rem;
        line-height: 2.25rem;
    }
    h2 {
        font-size: 1.375rem;
        line-height: 1.9375rem;
    }
    #scroll-to-top {
        bottom: calc(86px + 20px);
        z-index: 999;
    }

    .footer-container {
        justify-content: center;
    }

    .container {
        margin: 40px 0 0 0;
        width: 100%;
    }

    .container .content {
        width: 100%;
    }
    .flexRow .widget {
        min-width: unset;
    }

    .flexRow {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .flexRow .widget {
        max-width: unset;
        width:100%;
    }

     .charts-container {
        display: block;
    }

    .charts-container div {
        width: calc(100% - 32px);
    }

    .widget .report {
        display: block;
    }

    .widget .report .report-image,
    .widget .report .report-content {
        margin: 0;
        width: unset;
    }

    .logo {
        margin-top: 20px;
        margin-right: 40px;
        margin-bottom: 20px;
        margin-left: 24px
    }

    footer {
        margin-top: 40px;
        margin-bottom: 160px;
        width: 100%;
    }

    .filter-form {
        align-items: center;
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        display: none;
    }
    .filter-form.shown {
        display: flex;
    }
    .filter-menu-btn {
        align-items: center;
        color: #048500;
        display: flex;
        justify-content: center;
        margin: 0 0 25px;
    }
    .filter-menu-btn > svg {
        margin-right: 4px;
    }
    .filter {
        margin-bottom: 10px;
    }

    .breadcrumbs {padding: 20px 40px 10px;}

    #mobile-menu {
        display: block;
        position: fixed;
        left: 0;
        bottom: 0;
        height: 86px;
        z-index: 999;
        width: 100vw;
    }

    .mobile-menu-open #wrapper {
        overflow: hidden;
    }

    body.mobile-menu-open {
        position: fixed;
    }

    .mobile-menu-open #mobileMenu  {
        position: absolute;
        bottom: 0;
        height: 75vh;
        background-color: #fff;
        transition: .3s;
        width: calc(100vw - 16px);
    }

    #no-drag-handle {
        display: none;
    }

    .mobile-menu-open #no-drag-handle {
        width: 60px;
        height: 4px;
        background-color: grey;
        display: unset;
        align-self: center;
        border-radius: 2px;
    }

    .navButton span {
        font-size: .6875rem;
        margin-top: 10px;
    }

    .navicon-button {
        display: inline-block;
        position: relative;
        padding: 10px;
        transition: 0.25s;
        cursor: pointer;
        user-select: none;
        opacity: 0.8;
        border: none;
        background-color: #fff;
    }

    .navicon-button .navicon:before, .navicon-button .navicon:after {
        transition: 0.25s;
    }

    .navicon {
        position: relative;
        width: 29px;
        height: 3px;
        background: #000;
        transition: 0.5s;
        border-radius: 2.5rem;
    }
    .navicon:before, .navicon:after {
        display: block;
        content: "";
        height: 3px;
        width: 29px;
        background: #000;
        position: absolute;
        z-index: -1;
        transition: 0.5s 0.25s;
        border-radius: 1rem;
    }
    .navicon:before {
        top: 9px;
    }
    .navicon:after {
        top: -9px;
    }

    .open.x .navicon {
        background: transparent;
    }
    .open.x .navicon:before {
        transform: rotate(-45deg);
        top: 0;
    }
    .open.x .navicon:after {
        transform: rotate(45deg);
        top: 0;
    }

    .navButton {
        display: inline-flex;
        flex-direction: column;
        align-self: center;
        text-align: center;
    }

    .mobile-menu-open .navButton {
        margin-top: 26px;
    }

    .mobile-menu-header {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        box-shadow: 2px 0 15px 2px rgb(0 0 0 / 20%);
        display: flex;
        height: 86px;
        justify-content: flex-start;
        min-height: 70px;
        padding: 23px 0 15px;
        transition: .3s;
        background-color: #fff;
        flex-direction: column;
        margin: 0 8px;
    }

    .mobile-menu-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .mobile-menu-list > li {
        border-bottom: 1px solid #E0E0E0;
        margin: 0 32px;
        padding: 8px 0;
    }
    .title {
        border-bottom: 1px solid #E0E0E0;
        margin: 20px 0 0;
        padding-bottom: 10px;
        text-align: center;
    }

    .mobile-menu-section-content {
        display: none;
    }

    .mobile-menu-open .mobile-menu-section-content {
        display: unset;
    }

    .breadcrumbs {padding: 20px 40px 10px;}
    nav.mainNav {display:none;}


}
